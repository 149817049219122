import React from 'react';
import * as S from './styled';
import { DataCard, Alert } from '../../../components';
import { Formik, Form } from 'formik';
import { SubmitButton, Input } from '../../../components/FormikElements';
import useBankStatementUploaderForm from './useBankStatementUploaderForm';
import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import moment from 'moment';

const BankStatementUpload = ({ customForm, handleNext, formIndex }) => {
  const {
    applicationLoading,
    uploadBankStatementLoading,
    uploadBankStatementError,
    handleUpload,
    handleDocumentPreview,
    bankStatementDetails,
    userLoading,
    decideJobStatusLoading,
    pollingPage,
    applicationBank,
  } = useBankStatementUploaderForm({ customForm, handleNext, formIndex });
  return (
    <S.Wrapper>
      {
        !pollingPage ? 
        <DataCard loading={applicationLoading || uploadBankStatementLoading || userLoading || decideJobStatusLoading }>
          <S.Title>Upload your bank statement</S.Title>
          <Text fontSize={'18px'}>
          Upload a pdf bank statement for the account details below:
          </Text>
          <UnorderedList>
            <ListItem>Account Number: <strong>{applicationBank?.accountNumber}</strong></ListItem>
            <ListItem>Account Name: <strong>{applicationBank?.accountName}</strong></ListItem>
            <ListItem>Bank: <strong>{applicationBank?.bank?.name}</strong></ListItem>
            <ListItem>Expected Duration: <strong>{moment().subtract(3, 'months').format('ll')} to {moment().format('ll')}</strong></ListItem>
          </UnorderedList>
          {uploadBankStatementError && (
            <Alert classes="error">{uploadBankStatementError}</Alert>
          )}
          <Formik
            initialValues={{
              statementPassword: '',
            }}
            onSubmit={values => handleUpload(values)}
          >
            <Form>
              <S.ImageUpload>
                <input
                  name="bankStatement"
                  type="file"
                  accept="application/pdf"
                  onChange={handleDocumentPreview}
                />
                <section>
                  <div>
                    <span>
                      {bankStatementDetails.fileName ||
                        'Click here to upload file (PDF)'}
                    </span>
                    <p> {bankStatementDetails.fileSize || ''}</p>
                  </div>
                </section>
              </S.ImageUpload>
              <S.Aside>
                {' '}
                Upload PDF files and File size should not exceed 1MB
              </S.Aside>
              <Input
                name="statementPassword"
                type="password"
                placeholder="Password"
              />
              <S.Aside>Please enter document password (if any)</S.Aside>
              <SubmitButton 
                value="Next" 
              />
            </Form>
          </Formik>
        </DataCard>
        :
        <DataCard> 
          <Flex flexDirection={'column'} w={'full'} align="center">
            <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="100" height="100" rx="50" fill="url(#paint0_linear_5451_5087)"/>
            <path d="M56.2013 40H45.7986C44.3409 40 43.0619 40.9847 43.0035 42.4036C42.93 44.1888 44.1855 45.3742 45.5042 46.4871C47.3285 48.0266 48.2406 48.7964 48.3363 49.7708C48.3513 49.9233 48.3513 50.0767 48.3363 50.2292C48.2406 51.2036 47.3285 51.9734 45.5042 53.5129C44.1492 54.6564 42.9262 55.7195 43.0035 57.5964C43.0619 59.0153 44.3409 60 45.7986 60H56.2013C57.659 60 58.938 59.0153 58.9964 57.5964C59.0429 56.4668 58.6243 55.342 57.7351 54.56C57.3297 54.2034 56.9088 53.8615 56.4957 53.5129C54.6714 51.9734 53.7593 51.2036 53.6636 50.2292C53.6486 50.0767 53.6486 49.9233 53.6636 49.7708C53.7593 48.7964 54.6714 48.0266 56.4957 46.4871C57.8365 45.3556 59.0728 44.2581 58.9964 42.4036C58.938 40.9847 57.659 40 56.2013 40Z" stroke="#0235DD" strokeWidth="2"/>
            <path d="M48 59.6381C48 59.1962 48 58.9752 48.0876 58.7821C48.1015 58.7514 48.117 58.7214 48.134 58.6923C48.241 58.509 48.4221 58.3796 48.7843 58.1208C49.7905 57.4021 50.2935 57.0427 50.8652 57.0045C50.955 56.9985 51.045 56.9985 51.1348 57.0045C51.7065 57.0427 52.2095 57.4021 53.2157 58.1208C53.5779 58.3796 53.759 58.509 53.866 58.6923C53.883 58.7214 53.8985 58.7514 53.9124 58.7821C54 58.9752 54 59.1962 54 59.6381V60H48V59.6381Z" stroke="#0235DD" strokeWidth="2" strokeLinecap="round"/>
            <defs>
            <linearGradient id="paint0_linear_5451_5087" x1="50.5" y1="0" x2="50.5" y2="100" gradientUnits="userSpaceOnUse">
            <stop offset="0.446547" stopColor="#FAFAFA"/>
            <stop offset="0.740998" stopColor="#BBC8F3"/>
            <stop offset="1" stopColor="#0235DD"/>
            </linearGradient>
            </defs>
          </svg>

            <S.Title style={{ marginTop: '30px', fontSize: '24px' }}>Application Processing</S.Title>
            <S.Subtitle style={{ fontSize: '14px' }}>
              Please wait while we process your application, this takes less than 60 seconds to complete. 
            </S.Subtitle>
          </Flex>
        </DataCard>
      }
    </S.Wrapper>
  );
};

export default BankStatementUpload;
